import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../enums/Approutes'
import { useTranslation } from 'react-i18next'
import LogoWhite from '../../../assets/images/logo-white.png'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="bg-primary text-white py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="mb-4 md:mb-0">
            <img src={LogoWhite} alt="Logo" className='w-18 h-14' />
          </div>
          <nav className="flex flex-col text-center md:flex-row space-y-2 md:space-y-0 md:space-x-6">
            <Link to={AppRoutes.PIN_MAP} className="hover:underline underline-offset-2">
              {t('navbar:pinMap')}
            </Link>
            <Link to={AppRoutes.PINNERS} className="hover:underline underline-offset-2">
              Pinners
            </Link>
            <Link to={AppRoutes.FEEDBACK} className="hover:underline underline-offset-2">
              Feedback
            </Link>
          </nav>
        </div>
        <div className="border-t border-white/20 text-center pt-8 flex flex-col md:flex-row justify-center items-center text-sm">
          <p>&copy; {new Date().getFullYear()} Pinnalo {t('common:copyleft')}<a href='https://www.suredi.it' target='_blank' rel="noreferrer" className='hover:underline underline-offset-2'>Suredi</a>{t('common:copyright')}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer